import React, { useEffect } from "react";
import Footer from "components/Footer";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { geoPhoneNumber, waUrl } from "components/Footer";

export default function ThankYou() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          "iframe[id='JotFormIFrame-243132541618451']",
          "https://form.jotform.com/"
        );
      }
    };
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
              <div className="flex-column lg:col-span-1 lg:mt-48">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">
                  Get in Touch
                </h2>
                <p className="mt-4 leading-7 text-gray-600">
                  Need BIM & CAD Services? Reach out to our team.
                </p>

                <div className="flex flex-col gap-2 mt-4">
                  <a
                    href="mailto:enquiry@bim.com.sg?Subject=BIM%20Service%20Enquiry"
                    target="_blank"
                    className="flex flex-row text-black gap-3 transition-all duration-300 ease-in-out hover:text-main-primary hover:opacity-80"
                  >
                    <IoIosMail size={20} /> enquiry@bim.com.sg
                  </a>
                  <a
                    href={waUrl}
                    target="_blank"
                    className="flex flex-row text-black gap-3 transition-all duration-300 ease-in-out hover:text-main-primary hover:opacity-80 align-center"
                  >
                    <FaWhatsapp size={20} /> {geoPhoneNumber}
                  </a>
                </div>
              </div>
              <div className="flex flex-col lg:col-span-2 align-center items-center justify-center mt-32">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">
                  Thank You
                </h2>
                <p className="mt-4 leading-7 text-gray-600">
                  We have received your submission. We will be in
                  touch shortly.
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-48 pb-16 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Locations
                </h2>
                <p className="mt-4 text-gray-600">
                  Our local service teams are ready to help you.
                </p>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                <div className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Singapore
                  </h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    <p>
                      159 Sin Ming Road, Amtech Building Lobby 1
                      #05-03
                    </p>
                    <p>Singapore 575625</p>
                  </address>
                </div>
                <div className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    US, Delaware
                  </h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    <p>131 Continental Dr Suite 305 Newark</p>
                    <p>DE, 19713</p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
