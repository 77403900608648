import React, { useContext, useState, useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import awsmobile from "./src/aws-exports";
import GlobalContextProvider, {
  GlobalStateContext,
} from "./src/context/GlobalContextProvider";
import Layout from "./src/components/Layout";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { CenterLayer } from "./src/components/CenterLayer";
import ContactModal from "./src/components/Modal/ContactModal";
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { navigate } from "gatsby";
// import "http://db.onlinewebfonts.com/c/9a835ecd5504d7ec84ad5fa704987c94?family=National+2";

// import libraries

Amplify.configure(awsmobile);

async function loadMailchimp() {
  console.log("loading mailchimp");
  !(function (c, h, i, m, p) {
    (m = c.createElement(h)),
      (p = c.getElementsByTagName(h)[0]),
      (m.async = 1),
      (m.src = i),
      p.parentNode.insertBefore(m, p);
  })(
    document,
    "script",
    "https://chimpstatic.com/mcjs-connected/js/users/02482cbb1f113bc5dcb1886ae/bfb3544d36e366405cdbf1967.js"
  );
}

async function setApplicationMonitor() {
  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::517820625953:role/RUM-Monitor-ap-southeast-1-517820625953-5776609389961-Unauth",
      identityPoolId:
        "ap-southeast-1:646d2fa8-5672-4763-bde7-d1f93f952e29",
      endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = "03ec9ff4-163c-4cf8-a67c-13b9a2ae87c3";
    const APPLICATION_VERSION = "1.0.0";
    const APPLICATION_REGION = "ap-southeast-1";
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

export const registerServiceWorker = () => true;

export const onServiceWorkerUpdateReady = async () => {
  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // );
  // if (answer === true) {
  //   window.location.reload();
  // }
  // FORCE RELOAD OF PAGE WHEN THERE ARE UPDATES

  window.location.reload();
};
export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  //console.log("----------------", props);
  // loadMailchimp();
  const state = useContext(GlobalStateContext);
  const { contactLayer } = state;

  const [showWhatsApp, setShowWhatsApp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWhatsApp(true);
    }, 60000); // Delay of 3 seconds (3000ms)

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);


  return (
    <Layout {...props}>
      {contactLayer && <ContactModal />}
      {element}
      {showWhatsApp && (
        <FloatingWhatsApp
          phoneNumber="6580834020"
          accountName="Bimeco"
          chatMessage="Need BIM & CAD support? Please tell us more."
          avatar={"./bimeco-square-small.png"}
          onSubmit={() => navigate("/thankyou?ga=whatsapp")}
        />
      )}
    </Layout>
  );
};

export const onInitialClientRender = () => {
  setApplicationMonitor();
};
