import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FaFilePdf, FaPeopleArrows } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";
import { FaBuildingCircleCheck } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { SiAutocad } from "react-icons/si";

export const sections = () => {
  const imagedata = useStaticQuery(graphql`
    {
      schematic: file(relativePath: { eq: "raster-image.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      clash: file(relativePath: { eq: "schematic.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cde: file(relativePath: { eq: "cde.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      twothree: file(relativePath: { eq: "2dto3d.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const sectiondata = [
    {
      id: "gtm-bim",
      title: "Transform Legacy Files",
      content: `As-built structures typically have construction drawings in damaged or outdated files. These drawings need to be manually traced for them to be useable.`,
      linkText: null,
      linkHref:
        "https://blog.bim.com.sg/what-is-autodesk-bim-collaborate-pro-delivering-bim-for-your-project/",
      src: imagedata.schematic,
      bgColor: "bg-white",
    },
    {
      id: "gtm-review",
      title: "Ensure CAD Quality",
      content: `We review the completed CAD files to ensure that they are accurate and meet the project requirements. Our team will make any necessary revisions to the CAD files to ensure that they are up to standard.`,
      src: imagedata.clash,
    },
    {
      id: "gtm-iso",
      title: "PDF to BIM Conversion",
      content: `Create detailed BIM models from the CAD files. These models can be used for clash coordination, and sequencing for modern construction workflows`,
      src: imagedata.twothree,
    }
  ];
  console.log('data is', sectiondata)
  return sectiondata
};

export const WhyData = [
  {
    id: 1,
    img: "",
    icon: <FaFilePdf size={30} />,
    title: "Step 1: Share PDF Files",
    description:
      "Arrange for the collection & transfer of CAD files.",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    icon: <SiAutocad size={30} />,
    title: "Step 2: PDF to CAD",
    description:
      "Our BIM teams convert your PDF files to the latest CAD formats",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <FaCheckCircle size={30} />,
    title: "Step 3: Review",
    description:
      "Check final CAD files for accuracy and make any necessary revisions.",
  },
];

export const HowData = [
  {
    id: 1,
    img: "",
    icon: <FaFilePdf size={30} />,
    title: "Step 1: Share Files",
    description:
      "Share .pdf, .dwg, .dgn, and other CAD formats over a secure file submission link.",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    icon: <FaPeopleArrows size={30} />,
    title: "Step 2: Project Assignment",
    description:
      "Our BIM experts will review your project and allocate it to the right team.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <MdRateReview size={30} />,
    title: "Step 3: BIM Delivery",
    description:
      "Our BIM Team delivers your project within the agreed timeline.",
  },
];

export const faq = [
  {
    id: 4,
    question: "My drawings are really old and faded - can you still convert them to CAD?",
    answer:
      "Old drawings tend to be challenging to convert due to the fading of the ink. We have a team of experts who can manually trace the drawings to create vector CAD files.",
  },
  {
    id: 1,
    section: "bim-formats",
    question: "What are the types of CAD & BIM formats you support?",
    answer:
      "The most common CAD formats in the built-environment context is autocad (.dwg) and microstation (.dgn). We are able to support to the most common BIM formats such as Revit (.rvt), Archicad (.pln), and IFC.",
  },
  {
    id: 3,
    question: "We need to scan large quantities of large documents, can you help?",
    answer: "Engineering drawings tend to be in A0, A1 formats. We have large format scanners that can help you digitize such documents prior to CAD conversion. Schedule an appointment for our customer service teams to collect your files.",
  }
];
