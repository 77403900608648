import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import QuoteYellow from "assets/svgs/quote-yellow.svg";

const Feedback = () => {
  return (
    <section className="py-10 md:pt-[70px] lg:pt-[122px] md:pb-[70px] bg-[rgba(251,218,5,0.10)]">
      <div className="tw-container">
        <div className="grid md:grid-cols-3 gap-8 md:gap-10 lg:gap-[60px]">
          <div>
            <h2 className="text-3xl md:text-4xl text-black font-extrabold -tracking-[0.9px] mb-2">
              Here's what our clients have to say.
            </h2>
          </div>
          <div>
            <StaticImage
              src="../../../assets/images/terra-firma.png"
              alt="terra firma"
              objectFit="cover"
            />
            <div className="relative z-0 mt-5 md:mt-8 lg:mt-12">
              <QuoteYellow className="absolute -left-3 -top-2 -z-10" />
              <p className="mb-0 text-base italic font-medium text-black lg:text-lg">
                With the help of Bimeco, we were able to incorporate
                our BIM object libraries into our main contractors'
                BIM models. Ben worked with us closely to resolve all
                outstanding clashes ahead of time.
              </p>
            </div>
            <div className="flex items-center gap-2 mt-5 md:gap-4 md:mt-6">
              <StaticImage
                src="../../../assets/images/feedback-1.png"
                alt="Catherine Li,"
                className="rounded-full size-14 border-[3px] border-main-primary"
              />
              <div>
                <h5 className="mb-0 text-base font-semibold text-black">
                  Catherine Li,
                </h5>
                <p className="mb-0 text-sm text-gray-500">
                  Project Manager (Main Contractor)
                </p>
              </div>
            </div>
          </div>
          <div>
            <StaticImage
              src="../../../assets/images/climatepro.png"
              alt="climate pro"
              className="mix-blend-multiply"
            />
            <div className="relative z-0 mt-5 md:mt-8 lg:mt-12">
              <QuoteYellow className="absolute -left-3 -top-2 -z-10" />
              <p className="mb-0 text-base italic font-medium text-black lg:text-lg">
                We were short-handed during the document submissions
                stage. Thankfully we had the support of Faiz to
                prepare our 3D and 2D submissions.
              </p>
            </div>
            <div className="flex items-center gap-2 mt-5 md:gap-4 md:mt-6">
              <StaticImage
                src="../../../assets/images/feedback-2.png"
                alt=" Peter Tsai"
                className="rounded-full size-14 border-[3px] border-main-primary"
              />
              <div>
                <h5 className="mb-0 text-base font-semibold text-black">
                  Peter Tsai
                </h5>
                <p className="mb-0 text-sm text-gray-500">
                  Director (Subcontractor)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
