import React from "react";

interface CTAProps {
  header?: string;
  description?: string;
  ctaText?: string;
  ctaLink?: string;
  handleClick: () => void;
}

export const CTA = ({
  header,
  description,
  ctaText,
  ctaLink,
  handleClick,
}: CTAProps) => {
  return (
    <section className="py-10 md:py-16 bg-main-primary">
      <div className="tw-container">
        <div className="flex flex-col items-start justify-between gap-4 md:items-center md:flex-row">
          <div className="flex flex-col max-w-3xl">
            <h2 className="text-3xl md:text-4xl text-black font-extrabold -tracking-[0.9px] max-w-[680px] mb-0">
              {header || "Get Started on Your BIM Journey"}
            </h2>
            <p className="text-black">
              {description ||
                "Join the world's leading EPC Contractors and deliver projects with BIM today."}
            </p>
          </div>
          <button
            onClick={() => handleClick()}
            className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-white font-medium capitalize rounded-md shadow-shadow-sm bg-black py-[13px] px-4 md:px-[25px] shrink-0"
          >
            {ctaText || "Create a Project"}
          </button>
        </div>
      </div>
    </section>
  );
};
