import React, { useState } from "react";
import PlaybookforContractors from "../../components/_Landing/AutodeskLanding";
import SEO from "../../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <PlaybookforContractors />;
};

export default Services;

export const Head = ({ location }) => (
  <SEO
    title={"BIM Services | Revit Services in Singapore"}
    description={
      "Our BIM Coordinators develop Revit and CAD files for ACC Submissions. Gain free access to BIM Collaborate Pro with our BIM Services."
    }
    pathname={location.pathname}
  />
);
