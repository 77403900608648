import React, { useState } from "react";
import BIMObjectCreation from "../../components/_Landing/BIMObjectCreation";
import SEO from "../../components/Seo";

const Services = ({ location }) => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  const pathname = location.pathname;
  return <BIMObjectCreation />;
};

export default Services;

export const Head = () => (
  <SEO
    title={"CAD Services in Singapore | PDF/CAD & CAD/BIM Conversion"}
    description="Need to convert legacy PDFs to CAD or BIM? We provide CAD services in Singapore to help you with your project needs. Per-drawing rates available in excess of 100 drawings."
  />
);
