import React, { useEffect } from "react";
import Footer from "components/Footer";
import { CTA } from "components/CTA";
import Compare from "components/Compare";
import { navigate } from "gatsby";

export default function Pricing() {
  return (
    <>
      <Compare />
      <CTA
        header={"On-demand BIM Services"}
        description="Need BIM & CAD Support for your project? Subscribe to our BIM Services today."
        ctaText="Contact Us"
        handleClick={() => navigate("/contact")}
      />
      <Footer />
    </>
  );
}
