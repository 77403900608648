import React, { useState } from "react";
import BIM4D from "../../components/_Landing/4DBIM";
import SEO from "../../components/Seo";

const Services4D = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <BIM4D />;
};

export default Services4D;

export const Head = ({ location }) => (
  <SEO
    title={"4D BIM Services in Singapore | Virtual Site Planning"}
    description="By combining your BIM model with a project schedule, you can create a detailed construction sequence for site planning purposes. Work with our BIM Managers to develop a 4D BIM model today."
    pathname={location.pathname}
  />
);
