import React, { useState, useEffect } from "react";
import { Box, DateInput } from "grommet";
import { API, graphqlOperation } from "aws-amplify";
import { useForm, Controller, set } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowTurnRight,
} from "@fortawesome/free-solid-svg-icons";
import { Grommet } from "grommet";
import { useMediaQuery } from "react-responsive";
import { Link, navigate } from "gatsby";
import "./styles.css";

const companyData = [
  {
    name: "Main Contractor",
    image: "/icons/maincon.svg",
  },
  {
    name: "Subcontractor",
    image: "/icons/subcon.svg",
  },
  {
    name: "Consultant",
    image: "/icons/owner.svg",
  },
  {
    name: "Government",
    image: "/icons/government.svg",
  },
];

const PriceEstimation = () => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    register,
    getValues,
  } = useForm();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [totalValue, setTotalValue] = useState<string>(null);
  const isTablet = useMediaQuery({
    query: "(max-width: 980px)",
  });

  async function onSubmit() {
    if (step < 2) setStep(step + 1);
    // Only execute submission logic when final step is reached
    handleCalculation();
  }

  const handleCompnayTypeChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleDuration = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, duration: value });
  };

  const handleCalculation = () => {
    const annualValue = () => {
      if (formData.activeCompany === "Subcontractor") {
        return 1 * 4000;
      } else if (formData.activeCompany === "Consultant") {
        return 2 * 4000;
      } else {
        return 3 * 4000;
      }
    };

    let { duration } = formData;

    let totalValue = annualValue() * duration;

    let projectValue = new Intl.NumberFormat("en-SG", {
      style: "currency",
      currency: "SGD",
    }).format(totalValue);

    setTotalValue(projectValue);
  };

  const getNextButtonDisabledStatus = () => {
    if (step === 0 && formData?.activeCompany) {
      return false;
    }
    if (step === 1 && formData?.duration) {
      return false;
    }

    if (step === 2 && formData?.projectDuration) {
      return false;
    }

    if (step === 3) {
      return true;
    }
    return true;
  };

  const handleBack = () => {
    setStep(0);
  };

  const skipSteps = () => {
    const params = new URL(window.location.href).searchParams;
    const count = params.get("count");
    const duration = params.get("duration");
    if (count && duration) {
      const value = count * duration * 4000;
      const projectVal = new Intl.NumberFormat("en-SG", {
        style: "currency",
        currency: "SGD",
      }).format(value);
      setTotalValue(projectVal);
      setStep(2);
    }
  };

  useEffect(() => {
    skipSteps();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="price_estimation">
      <Box className="wrapper" pad="large">
        <Box className="question_header">
          {step < 4 && <h2>Need a Quote?</h2>}
          {step === 4 && <h2>We're working on it.</h2>}

          {step === 4 && (
            <p>
              Someone will contact you within 3 working days to give
              you a customised price quote. In the meantime, check out
              some of these links if you want to learn more about us!
            </p>
          )}
          {step == 0 && (
            <h5>What type of company do you work for?</h5>
          )}
          {step === 1 && (
            <h5>What is the project duration (months)?</h5>
          )}
          {step === 2 && totalValue ? (
            <Box>
              <h5>Esimated BIM project cost for your company</h5>
              <Link className="text-s" to="/#bim-packages">
                Package Details
              </Link>
              <Box className="questionnaire-answer-icon">
                <h3>{totalValue}</h3>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className="questionnaire-answers icon-select">
          <Box className="answers-row">
            {step === 0 &&
              companyData.map((company, index) => (
                <Box
                  className={
                    isTablet
                      ? "questionnaire-answer-wrapper w-50"
                      : "questionnaire-answer-wrapper col-5"
                  }
                  key={index}
                >
                  <Box
                    className={
                      formData?.activeCompany === company.name
                        ? "questionnaire-answer active"
                        : "questionnaire-answer"
                    }
                    onClick={() =>
                      handleCompnayTypeChange(
                        "activeCompany",
                        company.name
                      )
                    }
                  >
                    <img
                      className="questionnaire-answer-icon"
                      src={company.image}
                      alt={company.name}
                    />
                    <p className="questionnaire-answer-text">
                      {company.name}
                    </p>
                  </Box>
                </Box>
              ))}

            {step === 1 && (
              <div className="flex flex-row gap-3">
                <input
                  type="number"
                  placeholder="Months"
                  onChange={handleDuration}
                  value={formData.duration}
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    if (e.key === "Enter") {
                      console.log("hit!");
                      onSubmit();
                    }
                  }}
                />
              </div>
            )}
          </Box>
        </Box>

        {step < 3 && (
          <div className="flex flex-row gap-3 justify-center">
            {step > 0 && (
              <button
                className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                onClick={() => handleBack()}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;&nbsp;Back
              </button>
            )}
            {step < 2 && (
              <button
                disabled={getNextButtonDisabledStatus()}
                className={
                  "text-black rounded-md bg-main-primary px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-main-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                type="submit"
              >
                Next&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            )}
          </div>
        )}
      </Box>
    </form>
  );
};

export default PriceEstimation;
